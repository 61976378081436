import { NextRouter, useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import styles from '../../styles/LanguageSwitcher.module.scss'

function LanguageSwitcher() {

  const router: NextRouter = useRouter();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  const lngRef = useRef<HTMLDivElement>(null);

  const { locale: selectedLocale, locales, asPath, pathname, query } = router;

  useEffect(() => {
    const mouseDownHandler = (event: any) => {
      if (isLanguageDropdownOpen && lngRef.current && !lngRef.current.contains(event.target)) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', mouseDownHandler);
    return () => {
      // Unbind the event listeners
      document.removeEventListener('mousedown', mouseDownHandler);
    };
  }, [isLanguageDropdownOpen]);

  const handleChangeLanguage = (language: string) => {
    router.push(pathname, asPath, { locale: language });

    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);

    router.events.on('routeChangeComplete', () => {
      // page refresh
      router.reload();
    });
  };

  const activeLanguage = selectedLocale === 'en' ? 'English' : 'Ελληνικά';

  const uniqueLanguages = locales ? locales.filter((element, index) => element !== 'default') : [];
  uniqueLanguages.sort(function(x,y){ return x == selectedLocale ? -1 : y == selectedLocale ? 1 : 0; });

  return (
    <div className={styles.navItem} ref={lngRef}>
      <button
        onClick={() => {
          setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
        }}
        className={styles.expandBtn}
        aria-label="main menu"
        aria-haspopup="menu"
        aria-expanded={isLanguageDropdownOpen}
      >
        {activeLanguage}
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20" fill="#404546">
          <path d="M19 6.25l-1.5-1.5-7.5 7.5-7.5-7.5L1 6.25l9 9 9-9z"/>
        </svg>
      </button>
      <ul
        className={
          isLanguageDropdownOpen ? `${styles.dropdown} ${styles.open}` : styles.dropdown
        }
      >
        {uniqueLanguages?.map(locale => {
          return (
            <li key={locale}>
              <span onClick={() => handleChangeLanguage(locale)}>{locale === 'en' ? 'English' : 'Ελληνικά'}</span>
            </li>
          );
        })}
      </ul>
    </div>
  )
}

export default LanguageSwitcher;