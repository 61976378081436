import Image from 'next/image'
import { useRouter } from 'next/router'

import { i18nTexts } from '@shared/utils/i18n.util'

type FooterProps = {
  logo: string | undefined;
  copyrights: string | undefined;
  phoneNumber: string | undefined;
  emailAddress: string | undefined;
  address: string | undefined;
  facebook: string | undefined;
  twitter: string | undefined;
  instagram: string | undefined;
}

function Footer({ logo, copyrights, phoneNumber, emailAddress, address, facebook, twitter, instagram }: FooterProps) {
  const { locale: language, asPath, pathname } = useRouter();
  const currentPath = pathname?.length ? pathname.slice(1) : pathname; // remove the "/"
  const homeUrl = `/${language}`;
  const texts = i18nTexts.find(item => item.id === language);

  return (
    <footer className="pt-24 pb-12 bg-gray-900">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap justify-between -mx-4 pb-20">
          <div className="w-full lg:w-1/4 xl:w-1/6 px-4 mb-16 lg:mb-0">
            <a className="inline-block mb-4" href={homeUrl}>
              <Image className="logo" src={logo || ''} alt="logo" width="145" height="62"></Image>
            </a>
            <span className="block text-gray-300 mb-6">{copyrights}</span>
            {/* <a className="inline-block font-heading text-white hover:text-gray-100 mr-8" href="#">Terms</a><a className="inline-block font-heading text-white hover:text-gray-100" href="#">Privacy</a> */}
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-auto xl:mr-20 px-4 mb-16 lg:mb-0">
            <h4 className="font-heading text-lg text-white mb-8">{texts?.pages.pagesLabel}</h4>
            <ul>
              <li className="mb-6"><a className="font-heading text-white hover:text-gray-100" href={homeUrl} aria-label="Home Page">{texts?.pages.homeLabel}</a></li>
              <li className="mb-6"><a className="font-heading text-white hover:text-gray-100" href={`${homeUrl}/about`} aria-label="About Page">{texts?.pages.aboutLabel}</a></li>
              <li className="mb-6"><a className="font-heading text-white hover:text-gray-100" href={`${homeUrl}/services`} aria-label="Services Page">{texts?.pages.servicesLabel}</a></li>
              <li><a className="font-heading text-white hover:text-gray-100" href={currentPath === "" ? "#contact" : homeUrl + '#contact'}>{texts?.generic.contactLabel}</a></li>
            </ul>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-auto xl:mr-6 px-4 mb-16 lg:mb-0">
            <h4 className="font-heading text-lg text-white mb-6">{texts?.generic.contactInfoLabel}</h4>
            <div className="flex items-center mb-2">
              <div className="flex items-center justify-center w-10 h-10 mr-3 border border-gray-500 rounded-full">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.993636 2.24838C1.20452 1.89921 2.36641 0.629197 3.19556 0.667537C3.44341 0.688076 3.66251 0.838013 3.84053 1.01191C4.24928 1.41106 5.41939 2.9207 5.48581 3.23837C5.64739 4.01749 4.71897 4.46662 5.00311 5.25191C5.7275 7.02445 6.97566 8.27255 8.74898 8.99622C9.53362 9.28034 9.98277 8.35197 10.7619 8.51423C11.0789 8.58064 12.5893 9.75069 12.9885 10.1594C13.1617 10.3367 13.3124 10.5565 13.3329 10.8044C13.3637 11.6773 12.0149 12.8555 11.752 13.0062C11.1317 13.4498 10.3224 13.4423 9.33575 12.9836C6.58266 11.8382 2.18292 7.5215 1.01623 4.66448C0.56982 3.68339 0.53901 2.86866 0.993636 2.24838Z" fill="#25ab73"></path>
                </svg>
              </div>
              <span className="font-heading text-white">{phoneNumber}</span>
            </div>
            <div className="flex items-center mb-2">
              <div className="flex items-center justify-center w-10 h-10 mr-3 border border-gray-500 rounded-full">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M4.59183 2.33337H10.8771C11.7834 2.34354 12.6459 2.72666 13.2639 3.39351C13.8819 4.06036 14.2014 4.95273 14.1479 5.86279V10.2147C14.2014 11.1248 13.8819 12.0171 13.2639 12.684C12.6459 13.3508 11.7834 13.734 10.8771 13.7441H4.59183C2.64523 13.7441 1.33325 12.1605 1.33325 10.2147V5.86279C1.33325 3.91701 2.64523 2.33337 4.59183 2.33337Z" fill="#25ab73" stroke="#25ab73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M11.5118 6.04077L8.66819 8.33029C8.13004 8.7522 7.37566 8.7522 6.83752 8.33029L3.96948 6.04077" stroke="#1F2E3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </div>
              <span className="font-heading text-white"><a href={`mailto:${emailAddress}`}>{emailAddress}</a></span>
            </div>
            <div className="flex items-center mb-4">
              <div className="flex items-center justify-center w-10 h-10 mr-3 border border-gray-500 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#25ab73" className="w-4 h-4">
                  <path fillRule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clipRule="evenodd" />
                </svg>
              </div>
              <span className="font-heading text-white"><a href={`https://www.google.com/maps/search/?api=1&query=${address}`} target="_blank" rel="noreferrer">{address}</a></span>
            </div>
          </div>
          <div className="w-full lg:w-1/4 xl:w-auto px-4">
            <h4 className="font-heading text-lg text-white mb-8">{texts?.generic.followLabel}</h4>
            <div className="flex items-center">
              <a className="flex items-center justify-center w-12 h-12 mr-2 rounded-full bg-gray-800 hover:bg-gray-700" href={facebook} aria-label="Facebook">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.08979 13.8181V8.51062H10.9054L11.1778 6.44158H9.08979V5.1208C9.08979 4.52195 9.25864 4.11384 10.1352 4.11384L11.2512 4.11339V2.26277C11.0582 2.23817 10.3957 2.18176 9.62456 2.18176C8.01431 2.18176 6.9119 3.14582 6.9119 4.91588V6.44158H5.09082V8.51062H6.9119V13.8181H9.08979Z" fill="white"></path>
                  <mask id="mask0_37_110" style={{ ["maskType" as any]: 'alpha'}} maskUnits="userSpaceOnUse" x="5" y="2" width="7" height="12">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.08979 13.8181V8.51062H10.9054L11.1778 6.44158H9.08979V5.1208C9.08979 4.52195 9.25864 4.11384 10.1352 4.11384L11.2512 4.11339V2.26277C11.0582 2.23817 10.3957 2.18176 9.62456 2.18176C8.01431 2.18176 6.9119 3.14582 6.9119 4.91588V6.44158H5.09082V8.51062H6.9119V13.8181H9.08979Z" fill="white"></path>
                  </mask>
                </svg>
              </a>
              <a className="flex items-center justify-center w-12 h-12 mr-2 rounded-full bg-gray-800 hover:bg-gray-700" href={twitter} aria-label="Twitter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.5455 4.09728C14.0905 4.29892 13.6022 4.43566 13.0892 4.49671C13.613 4.18304 14.014 3.6855 14.204 3.09447C13.7127 3.38496 13.1703 3.59589 12.5924 3.71023C12.1296 3.21655 11.4714 2.90906 10.7413 2.90906C9.34059 2.90906 8.2049 4.04475 8.2049 5.44467C8.2049 5.64322 8.2273 5.83714 8.27057 6.02257C6.16298 5.91671 4.29411 4.90696 3.0433 3.37259C2.82465 3.74653 2.70027 4.18225 2.70027 4.64736C2.70027 5.52734 3.14837 6.30379 3.82825 6.75805C3.41259 6.74415 3.02166 6.62981 2.67942 6.43974V6.47142C2.67942 7.69983 3.55399 8.72504 4.71362 8.95837C4.50116 9.01554 4.27712 9.04722 4.04534 9.04722C3.88156 9.04722 3.72318 9.031 3.56788 9.00009C3.89081 10.0083 4.8272 10.7415 5.93663 10.7616C5.06902 11.4415 3.97504 11.8455 2.78681 11.8455C2.58209 11.8455 2.38043 11.8332 2.18188 11.8108C3.30445 12.5316 4.63716 12.9519 6.06952 12.9519C10.7351 12.9519 13.2854 9.0874 13.2854 5.73595L13.2769 5.4076C13.7752 5.05219 14.2063 4.60564 14.5455 4.09728Z" fill="white"></path>
                  <mask id="mask0_37_113" style={{ ["maskType" as any]: 'alpha'}} maskUnits="userSpaceOnUse" x="2" y="2" width="13" height="11">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.5455 4.09728C14.0905 4.29892 13.6022 4.43566 13.0892 4.49671C13.613 4.18304 14.014 3.6855 14.204 3.09447C13.7127 3.38496 13.1703 3.59589 12.5924 3.71023C12.1296 3.21655 11.4714 2.90906 10.7413 2.90906C9.34059 2.90906 8.2049 4.04475 8.2049 5.44467C8.2049 5.64322 8.2273 5.83714 8.27057 6.02257C6.16298 5.91671 4.29411 4.90696 3.0433 3.37259C2.82465 3.74653 2.70027 4.18225 2.70027 4.64736C2.70027 5.52734 3.14837 6.30379 3.82825 6.75805C3.41259 6.74415 3.02166 6.62981 2.67942 6.43974V6.47142C2.67942 7.69983 3.55399 8.72504 4.71362 8.95837C4.50116 9.01554 4.27712 9.04722 4.04534 9.04722C3.88156 9.04722 3.72318 9.031 3.56788 9.00009C3.89081 10.0083 4.8272 10.7415 5.93663 10.7616C5.06902 11.4415 3.97504 11.8455 2.78681 11.8455C2.58209 11.8455 2.38043 11.8332 2.18188 11.8108C3.30445 12.5316 4.63716 12.9519 6.06952 12.9519C10.7351 12.9519 13.2854 9.0874 13.2854 5.73595L13.2769 5.4076C13.7752 5.05219 14.2063 4.60564 14.5455 4.09728Z" fill="white"></path>
                  </mask>
                </svg>
              </a>
              <a className="flex items-center justify-center w-12 h-12 mr-2 rounded-full bg-gray-800 hover:bg-gray-700" href={instagram} aria-label="Instagram">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.06713 1.45459H10.9328C12.9249 1.45459 14.5456 3.07525 14.5455 5.06721V10.9329C14.5455 12.9248 12.9249 14.5455 10.9328 14.5455H5.06713C3.07518 14.5455 1.45459 12.9249 1.45459 10.9329V5.06721C1.45459 3.07525 3.07518 1.45459 5.06713 1.45459ZM10.9329 13.384C12.2845 13.384 13.3841 12.2845 13.3841 10.9329H13.384V5.06721C13.384 3.7157 12.2844 2.61608 10.9328 2.61608H5.06713C3.71562 2.61608 2.61608 3.7157 2.61608 5.06721V10.9329C2.61608 12.2845 3.71562 13.3841 5.06713 13.384H10.9329ZM4.57147 8.00012C4.57147 6.10954 6.1095 4.57147 8.00004 4.57147C9.89059 4.57147 11.4286 6.10954 11.4286 8.00012C11.4286 9.89063 9.89059 11.4286 8.00004 11.4286C6.1095 11.4286 4.57147 9.89063 4.57147 8.00012ZM5.75203 8.00004C5.75203 9.23958 6.76054 10.248 8.00005 10.248C9.23955 10.248 10.2481 9.23958 10.2481 8.00004C10.2481 6.76043 9.23963 5.75198 8.00005 5.75198C6.76046 5.75198 5.75203 6.76043 5.75203 8.00004Z" fill="white"></path>
                  <mask id="mask0_37_116" style={{ ["maskType" as any]: 'alpha'}} maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.06713 1.45459H10.9328C12.9249 1.45459 14.5456 3.07525 14.5455 5.06721V10.9329C14.5455 12.9248 12.9249 14.5455 10.9328 14.5455H5.06713C3.07518 14.5455 1.45459 12.9249 1.45459 10.9329V5.06721C1.45459 3.07525 3.07518 1.45459 5.06713 1.45459ZM10.9329 13.384C12.2845 13.384 13.3841 12.2845 13.3841 10.9329H13.384V5.06721C13.384 3.7157 12.2844 2.61608 10.9328 2.61608H5.06713C3.71562 2.61608 2.61608 3.7157 2.61608 5.06721V10.9329C2.61608 12.2845 3.71562 13.3841 5.06713 13.384H10.9329ZM4.57147 8.00012C4.57147 6.10954 6.1095 4.57147 8.00004 4.57147C9.89059 4.57147 11.4286 6.10954 11.4286 8.00012C11.4286 9.89063 9.89059 11.4286 8.00004 11.4286C6.1095 11.4286 4.57147 9.89063 4.57147 8.00012ZM5.75203 8.00004C5.75203 9.23958 6.76054 10.248 8.00005 10.248C9.23955 10.248 10.2481 9.23958 10.2481 8.00004C10.2481 6.76043 9.23963 5.75198 8.00005 5.75198C6.76046 5.75198 5.75203 6.76043 5.75203 8.00004Z" fill="white"></path>
                  </mask>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center pt-10 border-t border-gray-600">
          <span className="font-heading text-white"></span>
          <a className="font-heading text-white hover:text-gray-100" href="#">{texts?.generic.goUpLabel}</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
  