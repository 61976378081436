
export interface PageModel {
  id: number;
  slug: string;
  status: string;
  type: string;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
    protected: boolean;
  };
  menu_order: number;
  language: string;
}

export class PageViewModel {
  id: number;
  slug: string;
  status: string;
  type: string;
  title: string;
  content: string;
  menuOrder: number;
  language: string;

  constructor(res: PageModel, locale: string) {
    this.id = res.id;
    this.slug = res.slug;
    this.status = res.status;
    this.title = res.title?.rendered;
    this.type = res.type;
    this.content = res.content?.rendered;
    this.menuOrder = res.menu_order;
    this.language = res.language || locale;
  }
}

export interface PageSectionModel {
  ID: number;
  post_name: string;
  post_status: string;
  post_type: string;
  post_title: string;
  acf: {
    title: string;
    subtitle_1: string;
    subtitle_2: string;
    description: string;
    related_services: SectionSolutionModel[];
    related_solutions: SectionSolutionModel[];
    background_image: string;
    background_video: string;
  }
}

export class PageSectionViewModel {
  sectionId: number;
  sectionSlug: string;
  sectionStatus: string;
  sectionType: string;
  sectionName: string;
  content: {
    title: string;
    subtitle_1: string;
    subtitle_2: string;
    description: string;
    services: SectionSolutionViewModel[];
    solutions: SectionSolutionViewModel[];
    backgroundImage: string;
    backgroundVideo: string;
  }

  constructor(res: PageSectionModel) {
    this.sectionId = res.ID;
    this.sectionSlug = res.post_name;
    this.sectionStatus = res.post_status;
    this.sectionName = res.post_title;
    this.sectionType = res.post_type;
    this.content = {
      title: res.acf.title,
      subtitle_1: res.acf.subtitle_1,
      subtitle_2: res.acf.subtitle_2,
      description: res.acf.description,
      services: (res.acf.related_services || []).map((service: SectionSolutionModel) => new SectionSolutionViewModel(service)),
      solutions: (res.acf.related_solutions || []).map((solution: SectionSolutionModel) => new SectionSolutionViewModel(solution)),
      backgroundImage: res.acf.background_image,
      backgroundVideo: res.acf.background_video
    };
  }
}

export interface SectionSolutionModel {
  ID: number;
  post_name: string;
  post_status: string;
  post_type: string;
  post_title: string;
  menu_order: number;
  acf: {
    description: string;
    icon: string;
  }
}

export class SectionSolutionViewModel {
  id: number;
  title: string;
  slug: string;
  content: string;
  icon: string;
  status: string;
  type: string;
  menuOrder: number;

  constructor(res: SectionSolutionModel) {
    this.id = res.ID;
    this.title = res.post_title;
    this.slug = res.post_name;
    this.content = res.acf.description;
    this.icon = res.acf.icon;
    this.status = res.post_status;
    this.type = res.post_type;
    this.menuOrder = res.menu_order;
  }
}

export interface PageAcfModel {
  subtitle: string;
  subtitle_2: string;
  background_image: string;
  meta_description: string;
  site_info: PageAcfSiteInfoModel[];
}

export interface PageAcfSiteInfoModel {
  ID: number;
  post_title: string;
  post_content: string;
  post_name: string;
  post_status: string;
  menu_order: number;
  acf: any;
}

export class PageAcfGeneralInformationViewModel {
  logo: string;
  keywords: string;
  emailAddress: string;
  address: string;
  phoneNumber: string;
  copyrights: string;
  socialMedia: {
    facebook: string;
    instagram: string;
    twitter: string;
  }

  constructor(res: PageAcfSiteInfoModel) {
    this.logo = res.acf.logo,
    this.keywords = res.acf.keywords,
    this.emailAddress = res.acf.email_address,
    this.address = res.acf.address,
    this.phoneNumber = res.acf.phone_number,
    this.copyrights = res.acf.copyrights,
    this.socialMedia = {
      facebook: res.acf.social_media.facebook,
      instagram: res.acf.social_media.instagram,
      twitter: res.acf.social_media.twitter
    }
  }
}