import Head from 'next/head'
import { useRouter } from 'next/router'

import { PageAcfGeneralInformationViewModel, PageAcfSiteInfoModel } from '@shared/models/page'

import Footer from './Footer'
import Navbar from './Navbar'

interface LayoutProps {
  children: any;
}

function Layout({ children }: LayoutProps) {
  const router = useRouter();
  const { locale: selectedLocale, locales, asPath: path} = router;

  const siteInfo = children.props.data.siteInfo as PageAcfSiteInfoModel[]
  const generalInfo = siteInfo?.find((info: PageAcfSiteInfoModel) => info.post_name === `general_information-${selectedLocale}`) as PageAcfSiteInfoModel | undefined;
  const generalInfoView = generalInfo ? new PageAcfGeneralInformationViewModel(generalInfo) : undefined;

  const logo = generalInfoView?.logo;
  const copyrights = generalInfoView?.copyrights;
  const phoneNumber = generalInfoView?.phoneNumber;
  const emailAddress = generalInfoView?.emailAddress;
  const address = generalInfoView?.address;
  const keywords = generalInfoView?.keywords;
  const facebook = generalInfoView?.socialMedia.facebook;
  const twitter = generalInfoView?.socialMedia.twitter;
  const instagram = generalInfoView?.socialMedia.instagram;

  const footerProps = {
    logo,
    copyrights,
    address,
    emailAddress,
    phoneNumber,
    facebook,
    twitter,
    instagram
  }

  return (
    <>
      <Head>
        <title>{`Green Value - ${children.props.title}`}</title>
        <meta name="description" content={children.props.description} />
        <meta name="keywords" content={keywords} />
        <link rel="icon" href="/favicon.ico" />
        {selectedLocale === 'el' 
          ? <>
              <link rel="canonical" href={`https://www.greenvalue.gr/el${path}`} />
              <link rel="alternate" hrefLang="en" href={`https://www.greenvalue.gr/en${path}`} />
            </>
          : <>
              <link rel="canonical" href={`https://www.greenvalue.gr/en${path}`} />
              <link rel="alternate" hrefLang="el" href={`https://www.greenvalue.gr/el${path}`} />
            </>
        }
      </Head>
      <Navbar logo={logo} copyrights={copyrights} />
      {children}
      <Footer {...footerProps} />
    </>
  );
}

export default Layout;
