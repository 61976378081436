import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { i18nTexts } from '@shared/utils/i18n.util'

import styles from '../../styles/Navbar.module.scss'
import LanguageSwitcher from './LanguageSwitcher'

type NavbarProps = {
  logo: string | undefined;
  copyrights: string | undefined;
}
  
function Navbar({ logo, copyrights }: NavbarProps) {

  const { locale: language, asPath, pathname } = useRouter();
  const currentPath = pathname?.length ? pathname.slice(1) : pathname; // remove the "/"
  const homeUrl = `/${language}`;
  const texts = i18nTexts.find(item => item.id === language);

  const [isNavExpanded, setNavIsExpanded] = useState(false);
  const navIsHiddenClass = isNavExpanded
    ? ''
    : 'hidden '
  return (
    <>
      <nav className="py-4 bg-white">
        <div className="container mx-auto px-4">
          <div className="relative flex justify-between">
            <div className="w-auto">
              <Link className="inline-block" href="/" locale={language}>
                <Image className={styles.logo} src={logo || ''} alt="logo" width="165" height="70"></Image>
              </Link>
            </div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:block">
              <ul className="flex items-end">
                <li className="font-heading mr-12 text-base">
                  <Link className={(currentPath === "" ? "font-semibold " : "") + 'hover:font-semibold'} href="/" locale={language} aria-label="Home Page">
                    { texts?.pages.homeLabel}
                  </Link>
                </li>
                <li className="font-heading mr-12 text-base">
                  <Link className={(currentPath.includes('about') ? "font-semibold " : "") + 'hover:font-semibold'} href="/about" locale={language} aria-label="About Page">
                    { texts?.pages.aboutLabel}
                  </Link>
                </li>
                <li className="font-heading mr-12 text-base">
                  <Link className={(currentPath.includes('services') ? "font-semibold " : "") + 'hover:font-semibold'} href="/services" locale={language} aria-label="Services Page">
                    {texts?.pages.servicesLabel}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-auto hidden lg:flex items-center">
              <LanguageSwitcher />
              <a className="inline-block py-4 px-8 font-heading font-medium text-base text-white bg-green-500 hover:bg-green-600 border border-green-500 hover:border-green-600 rounded-sm transition duration-200" href={currentPath === "" ? "#contact" : homeUrl + '#contact'}>{texts?.generic.contactLabel}</a>
            </div>
            <div className="w-auto lg:hidden mt-2">
              <button className="navbar-burger inline-flex w-14 h-14 justify-center items-center bg-gray-500 hover:bg-gray-600 rounded-full" aria-label="expand menu" onClick={() => {
                setNavIsExpanded(true);
              }}>
              <svg width="20" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 9H1M19 1H1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className={navIsHiddenClass + "navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50"}>
        <div className="navbar-backdrop fixed inset-0 backdrop-blur-xl backdrop-filter bg-gray-900 bg-opacity-80"></div>
        <nav className="relative pt-7 pb-8 bg-white h-full overflow-y-auto">
          <div className="flex flex-col px-6 h-full">
            <div className={styles.topMenu}>
              <Link className="inline-block" href="/" locale={language} aria-label="Home Page">
                <Image src={logo || ''} alt="logo" width="140" height="59"></Image>
              </Link>
              <button aria-label="collapse menu" onClick={() => {
                setNavIsExpanded(false)
              }}>
                <svg fill="#404546" height="30px" width="30px" version="1.1" id="closeBtn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460.775 460.775">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path> </g>
                </svg>
              </button>
            </div>
            <ul className="w-full mb-auto pb-16 pt-8">
              <li onClick={() => {
                setNavIsExpanded(false)
              }}><Link className={(currentPath === "" ? "font-extrabold " : "") + 'font-heading block text-lg text-center font-medium py-4 px-6 hover:bg-green-50 rounded-sm'} href="/" locale={language} aria-label="Home Page">{texts?.pages.homeLabel}</Link></li>
              <li onClick={() => {
                setNavIsExpanded(false)
              }}><Link className={(currentPath.includes('about') ? "font-extrabold " : "") + 'font-heading block text-lg text-center font-medium py-4 px-6 hover:bg-green-50 rounded-sm'} href="/about" locale={language} aria-label="About Page">{texts?.pages.aboutLabel}</Link></li>
              <li onClick={() => {
                setNavIsExpanded(false)
              }}><Link className={(currentPath.includes('services') ? "font-extrabold " : "") + 'font-heading block text-lg text-center font-medium py-4 px-6 hover:bg-green-50 rounded-sm'} href="/services" locale={language} aria-label="Services Page">{texts?.pages.servicesLabel}</Link></li>
              <li onClick={() => {
                setNavIsExpanded(false)
              }}><Link className="font-heading block text-lg text-center font-medium py-4 px-6 hover:bg-green-50 rounded-sm" href={currentPath === "" ? "#contact" : homeUrl + '#contact'} locale={language} aria-label="Contact Form">{texts?.generic.contactLabel}</Link></li>
              <li className="pt-5 pl-2" onClick={() => {
                setNavIsExpanded(false)
              }}>
              </li>
            </ul>
            <div className="w-full">
              <LanguageSwitcher />
              {/* <a className="block w-full py-4 px-4 mb-4 text-center font-heading font-medium text-base hover:text-green-500 border border-gray-900 hover:border-green-500 rounded-sm transition duration-200" href="#">Log in</a>
              <a className="block w-full py-4 px-4 mb-8 text-center font-heading font-medium text-base text-white bg-green-500 hover:bg-green-600 border border-green-500 hover:border-green-600 rounded-sm transition duration-200" href="#">Sign up</a> */}
              <p className="pl-2 pt-12 text-sm">{copyrights}</p>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navbar;