export const i18nTexts = [
  {
    id: 'en',
    generic: {
      addressLabel: 'Address',
      contactLabel: 'Contact',
      contactInfoLabel: 'Contact Info',
      followLabel: 'Follow us',
      goUpLabel: 'Go to top of page',
    },
    pages: {
      pagesLabel: 'Pages',
      aboutLabel: 'About',
      homeLabel: 'Home',
      servicesLabel: 'Services',
    },
    sections: {
      contact: {
        h1LabelOne: 'Let’s discuss about your needs',
        h1LabelTwo: 'Give us a call or drop a line, we would be pleased to advise you in person.',
        h2LabelOne: 'Feel free to ',
        h2LabelΤwo: 'contact us'
      }
    },
    forms: {
      sendLabel: 'Send now',
      sendingLabel: 'Sending...',
      contactFormLabel: 'Contact Form',
      companyFormLabel: 'Company / Organization',
      emailFormLabel: 'Email Address',
      firstNameFormLabel: 'First Name',
      lastNameFormLabel: 'Last Name',
      messageFormLabel: 'Message',
      phoneFormLabel: 'Phone Number',
      typeFormLabel: 'Type your ',
      thankyouLabel: 'Thank you for your message!'
    }
  },
  {
    id: 'el',
    generic: {
      addressLabel: 'Διεύθυνση',
      contactLabel: 'Επικοινωνία',
      contactInfoLabel: 'Στοιχεία Επικοινωνίας',
      followLabel: 'Ακολουθήστε μας',
      goUpLabel: 'Πήγαινε στην αρχή της σελίδας',
    },
    pages: {
      pagesLabel: 'Σελίδες',
      aboutLabel: 'Σχετικά',
      homeLabel: 'Αρχική',
      servicesLabel: 'Υπηρεσίες',
    },
    sections: {
      contact: {
        h1LabelOne: 'Ας συζητήσουμε για τις ανάγκες σας',
        h1LabelTwo: 'Καλέστε μας και θα χαρούμε να σας συμβουλεύσουμε από κοντά.',
        h2LabelOne: 'Μπορείτε να ',
        h2LabelΤwo: 'επικοινωνήσετε μαζί μας'
      }
    },
    forms: {
      sendLabel: 'Αποστολή',
      sendingLabel: 'Επεξεργασία...',
      contactFormLabel: 'Φόρμα Επικοινωνίας',
      companyFormLabel: 'Επωνυμία Επιχείρησης',
      emailFormLabel: 'Διεύθυνση E-mail',
      firstNameFormLabel: 'Όνομα',
      lastNameFormLabel: 'Επίθετο',
      messageFormLabel: 'Μήνυμα',
      phoneFormLabel: 'Αρ. Τηλεφώνου',
      typeFormLabel: 'Πληκτρολογήστε ',
      thankyouLabel: 'Ευχαριστούμε για την επικοινωνία!'
    }
  }
]